// import upArrow from './images/up-arrow.png';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import ecoleAM from './images/dessins_enfants/ecoleAM.png';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import Scroll from './Scroll';
import pse from './images/pse.jpg';
import pms from './images/pms.jpg';
import bla from './images/bla.png';
import romeo from './images/romeo.png';


function Footer() {
    return (


        <footer className="footerProject">

            <div className="dflexFooter">
                <div className="divFooter">
                    <h4>Ecole Saint Jean-Baptiste</h4>
                    <p>Rue Armand de Moor 19</p>
                    <p>1421 Bois-Seigneur-Isaac</p>
                    <p><FontAwesomeIcon icon={faPhoneAlt} />  067/21 90 09 - 02/384 43 99</p>
                </div>
                <div className="divFooter1">
                    <div className="">
                        <h4>Liens utiles</h4>
                        <ul className="ulLiensFooter">
                            <li className="liFooterFacebook"><a className="liensBasDePage" href="https://fr-fr.facebook.com/institutsaintjacques"><FontAwesomeIcon className="iconFacebook" icon={faFacebookSquare} /></a></li>
                            <li className="liFooterFacebook"><a className="liensBasDePage" href="https://www.ecole-anne-marie.be/"><img className="ecoleAM" src={ecoleAM} alt={ecoleAM} /></a></li>
                            <li className="liFooterFacebook"><a className="liensBasDePage" href="https://www.pselibrebw.be/"><img className="ecoleAM" src={pse} alt={pse} /></a></li>
                            <li className="liFooterFacebook"><a className="liensBasDePage" href="https://centrepms.be/bla/"><img className="ecoleAM" src={pms} alt={pms} /></a></li>
                            <li className="liFooterFacebook"><a className="liensBasDePage" href="https://www.braine-lalleud.be/ecole-communale-du-pre-vert/infos-pratiques/activites-extra-scolaires/aes"><img className="bla" src={bla} alt={bla} /></a></li>
                            <li className="liFooterFacebook"><a className="liensBasDePage" href="https://www.romeoasbl.be/"><img className="ecoleAM" src={romeo} alt={romeo} /></a></li>
                        </ul>
                    </div>

                    <Scroll /></div>
            </div>
            <div className="copyright"><p><strong>&copy;</strong>Ecole Saint-Jean-Baptiste <strong>{(new Date().getFullYear())}</strong></p>

                <p>Fait avec &#10084; par <strong><a href = 'https://emiliemontaigne.netlify.app/' >Emilie Montaigne</a></strong></p></div>
        </footer >


    );
}

export default Footer;