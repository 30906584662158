import piscine from './images/piscine.png';

function Piscine() {
    return (


        <div className="nosClassesTot">

            <div className="imgTop"><img src={piscine} alt={piscine} /></div>
            <div className="titrePage"> <h1 >Les cours de natation</h1></div>
            <div className="divTextDirection">
                <p>Dès le 14 septembre 2021, certaines classes se rendront les mardis après-midi à la piscine. Nous accompagnerons les élèves de l’Ecole Anne-Marie. Ceci permettra aux élèves des deux écoles de se rencontrer et de partager une activité́ commune.
                    Les cours de natation seront donnés de la 2ème maternelle à la 6ème primaire.</p>
                <p>En primaire, ce cours remplacera le cours de gymnastique à raison de +/- 12 séances par an.</p>

                <ul className="piscineUl">En primaire :
                    <li className="piscineLi">Trimestre 1 : P3/4</li>
                    <li className="piscineLi">Trimestre 2 : P5/6</li>
                    <li className="piscineLi">Trimestre 3 : P1/2</li></ul>
                <ul className="piscineUl">En maternelle :
                    <li className="piscineLi">Semestre 1 : M3 (+/-18 séances)</li>
                    <li className="piscineLi">Semestre 2 : M2 (+/-18 séances)</li></ul>
                <p>Cette initiation a lieu à la <a href="https://www.braine-lalleud.be/fr/citoyens/piscine-communale/">piscine de Braine-l’Alleud</a>. Les enfants sont encadrés par les institutrices, un maitre-nageur ainsi qu’un accompagnant supplémentaire. Nous accompagnerons les élèves de l’Ecole Anne-Marie. Le transport sera assuré par la société Keolis/Cardona.</p>
                <p>Il est souhaité́ que le jour de la piscine, les enfants aient des vêtements pratiques (<strong className="piscineStrong">éviter bas collants, chemisiers, ceintures, bretelles</strong>).</p>
            </div>
        </div >


    );
}

export default Piscine;