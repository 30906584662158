import React, { useState, useEffect } from 'react';
import upArrow from './images/up-arrow.png';

const Scroll = (showbelow) => {
    const [show, setShow] = useState(showbelow ? false : true)
    const handleScroll = () => {
        if (window.pageXOffset > showbelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }
    useEffect(() => {
        if (showbelow) {
            window.addEventListener('scroll', handleScroll)
        }
    })
    const handleClick = () => {
        window['scrollTo']({ top: 0, behavior: 'smooth' })
    }
    return (
        <div className="divUpArrow" onClick={handleClick}>
            <img className="upArrow" src={upArrow} alt={upArrow} />
        </div>
    )
}
export default Scroll;