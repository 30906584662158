
import React from 'react';

import './index.css';

import "./App.css";
import { useState } from "react";
import { BrowserRouter as Router, NavLink, Link, Route, Switch } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';


import Contact from './Contact';
import LaDirection from './LaDirection';
import Roi from './Roi';
import LesMaternelles from './LesMaternelles';
import LesPrimaires from './LesPrimaires';
import LaGarderie from './LaGarderie';
import Footer from './Footer';
import App from './App';


import sjb from './images/sjb.png';



import styled from 'styled-components'

import Activites from './Activites';
import Neerlandais from './Neerlandais';
import Adaptation from './Adaptation';

import Religion from './Religion';
import Piscine from './Piscine';

import Team from './Team';
import MatAcc from './MatAcc';
import Mat2 from './Mat2';


import Gym from './Gym';

import P1 from './P1';
import P3 from './P3';
import P5 from './P5';

import Rgpd from './Rgpd';



// = à la page Login




function NavBarStatic(props) {


  const [isOpen, setisOpen] = useState(false);
  const [isOpen1, setisOpen1] = useState(false);
  const [isShow, setisShow] = useState(false);
  const [isOpen2, setisOpen2] = useState(false);
  const [isOpen3, setisOpen3] = useState(false);





  const handleOpen = () => {
    setisShow(!isShow);

  }

  const handleOpen1 = () => {
    setisOpen(!isOpen);
    setisOpen1(false);
    setisOpen2(false);
    setisOpen3(false);



  }
  const handleOpen2 = () => {
    setisOpen(false);
    setisOpen1(!isOpen1);
    setisOpen2(false);
    setisOpen3(false);



  }
  const handleOpen3 = () => {
    setisOpen(false);
    setisOpen1(false);
    setisOpen2(!isOpen2);
    setisOpen3(false);


  }



  const handleClose = (e) => {
    if (e && e.relatedTarget) {
      e.relatedTarget.click();
    }
    setisShow(false);
    setisOpen(false);
    setisOpen1(false);
    setisOpen2(false);
    setisOpen3(false);
  }




  const StyledBurger = styled.button`
  @media (max-width: 1049px) { 
  transition: 300ms linear all;

.styleBurgerDiv.un {
  transform: ${() => isShow ? 'rotate(45deg)' : 'rotate(0)'};
box-shadow:${() => isShow ? 'unset' : '3px 3px black'};
top:${() => isShow ? '1px' : '0'};

}

.styleBurgerDiv.deux {
  opacity: ${() => isShow ? '0' : '1'};
  box-shadow:${() => isShow ? 'unset' : '3px 3px black'};

}

.styleBurgerDiv.trois {
  transform: ${() => isShow ? 'rotate(-45deg)' : 'rotate(0)'};
  box-shadow:${() => isShow ? 'unset' : '3px 3px black'};
}
}
`


  return (
    <div className="bgBody">

      <div className="container1">
        <img className="logoSjb" src={sjb} alt={sjb} />
        <StyledBurger className="styleBurger" onClick={handleOpen}>
          <div className="styleBurgerDiv un"></div>
          <div className="styleBurgerDiv deux"></div>
          <div className="styleBurgerDiv trois"></div>
        </StyledBurger>

        <div className="" >
          <Router>







            <div className={isShow ? 'montre' : 'cache'}>

              <div className="dflex">



                <ul className="dflexCompte">

                  <li className="liCompte titreNav"><NavLink exact activeClassName="active"  to="/" replace onClick={handleClose}>à PROPOS
                  </NavLink></li>

                  <li className="liCompte titreNav" onClick={handleOpen1}>NOTRE éCOLE<span className="caret"><FontAwesomeIcon icon={faCaretDown} /></span>
                    {isOpen &&

                      <ul className="dropdownMenu">
<li onClick={handleClose}><Link className="titreNavSub" to="/NotreEcole/LaDirection" >La Direction</Link></li>
                        <li onClick={handleClose}><Link className="titreNavSub" to="/NotreEcole/Roi" >Documents à télécharger</Link></li>
                        <li onClick={handleClose}><Link className="titreNavSub noBorder" to="/NotreEcole/Rgpd" >RGPD</Link></li>
                      </ul>}
                  </li>

                  <li className="liCompte titreNav" onClick={handleOpen2}>NOS CLASSES<span className="caret"><FontAwesomeIcon icon={faCaretDown} /></span>
                    {isOpen1 &&

                      <ul className="dropdownMenu">


                        <li onClick={handleClose}><Link className="titreNavSub" to="/NosClasses/LesMaternelles">Les maternelles</Link></li>
                        <li onClick={handleClose}><Link className="titreNavSub" to="/NosClasses/LesPrimaires">Les primaires</Link></li>
                        <li onClick={handleClose}><Link className="titreNavSub" to="/NosClasses/Religion">Religion</Link></li>
                        <li onClick={handleClose}><Link className="titreNavSub" to="/NosClasses/Neerlandais">Néerlandais</Link></li>
                        <li onClick={handleClose}><Link className="titreNavSub" to="/NosClasses/Adaptation">Adapation</Link></li>
                        <li onClick={handleClose}><Link className="titreNavSub noBorder" to="/NosClasses/Extra">Extra-scolaire</Link></li>

                      </ul>}</li>

                  <li className="liCompte titreNav" onClick={handleOpen3}>ACTIVITéS<span className="caret2"><FontAwesomeIcon icon={faCaretDown} /></span>
                    {isOpen2 &&

                      <ul className="dropdownMenu">
                        <li onClick={handleClose}><Link className="titreNavSub" to="/Activites/Gym">éducation physique</Link></li>
                        <li onClick={handleClose}><Link className="titreNavSub noBorder" to="/Activites/Piscine">Piscine</Link></li>

                      </ul>}</li>



                  <li className="liCompte titreNav"><NavLink activeClassName="active" className="dernierLi" to="/Contact" replace onClick={handleClose}>CONTACT
                  </NavLink></li>

                 

                </ul></div>

            



            </div>






            <Switch>
              <Route exact path="/" component={App} />
              <Route exact path="/NotreEcole" component={LaDirection} />
              <Route exact path="/NotreEcole/LaDirection" component={LaDirection} />
              <Route exact path="/NotreEcole/Roi" component={Roi} />
              <Route exact path="/NotreEcole/Rgpd" component={Rgpd} />
              {/*  <Route exact path="/NosClasses" component={NosClasses} /> */}
              <Route exact path="/NosClasses" component={Team} />
              <Route exact path="/NosClasses/LesMaternelles" component={LesMaternelles} />
              <Route exact path="/NosClasses/LesMaternelles/MatAcc" component={MatAcc} />
              <Route exact path="/NosClasses/LesMaternelles/Mat2" component={Mat2} />
              <Route exact path="/NosClasses/LesPrimaires" component={LesPrimaires} />
              <Route exact path="/NosClasses/Religion" component={Religion} />
              <Route exact path="/NosClasses/Neerlandais" component={Neerlandais} />
              <Route exact path="/NosClasses/Adaptation" component={Adaptation} />
              <Route exact path="/NosClasses/LesPrimaires/P1" component={P1} />
              <Route exact path="/NosClasses/LesPrimaires/P3" component={P3} />
              <Route exact path="/NosClasses/LesPrimaires/P5" component={P5} />
              <Route exact path="/NosClasses/Extra" component={LaGarderie} />
              <Route exact path="/Activites" component={Activites} />
              <Route exact path="/Activites/Gym" component={Gym} />
              <Route exact path="/Activites/Piscine" component={Piscine} />
              <Route exact path="/Contact" component={Contact} />




              {/* <Route path="/Compte/Galerie" component={GalerieParents} /> */}

              <Route component={Error} />


            </Switch>

          </Router>
        </div>
        <Footer />
      </div></div>
  )
}



export default NavBarStatic;

