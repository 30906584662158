import React from "react";
import dominique from './images/profs/dominique.png';
import justine from './images/profs/justine.png';

function Mat2() {


        return (
            <div>



                <div>

                            <div  className="nosClassesP3">

                                <div className="descClasseM2"> 
                                <h1 className="nomProf">Madame Dominique</h1>
                                <img className="imgPhotoProf7" src={dominique} alt='' />
                                <p>Dans le grand livre de ma classe, il était une fois...<br/><br/>

• Des coins pour jouer, construire, te sociabiliser…<br/><br/>

• Des ateliers pour apprendre les lettres, les chiffres,
les formes, les couleurs… parfois tout seul, parfois
en groupes…<br/><br/>

• Des crayons, des pastels, des pinceaux, de la peinture,
des ciseaux, du papier, de la colle… pour créer.<br/><br/>

• Des albums à lire, des livres d’histoires à raconter…
pour rêver et te donner le goût de lire.<br/><br/>

• Des activités extérieures pour découvrir la nature,
le monde qui t’entoure… faire pousser des plantes,
les récolter, les cuisiner.<br/><br/>

• Un coin tapis… pour parler, échanger, écouter,
prendre des responsabilités.<br/><br/>

Tout cela pour t’aider à apprendre, à grandir, à prendre confiance en toi afin que tu puisses créer ta propre histoire.</p></div>

                            </div >

                            <div  className="nosClassesP3">

<div className="descClasseM2"> 
<h1 className="nomProf">Madame Justine</h1>
<img className="imgPhotoProf7" src={justine} alt='' />
<p>Bonjour, je m’appelle Justine et cette année, je me joins à l’équipe maternelle de l’école Saint- Jean- Baptiste.<br/><br/>

En effet, je compléterai l’horaire de Madame Dominique le mercredi et vendredi en 2eme et 3eme maternelle.<br/><br/>

Forte de 10 années d’expériences dans l’enseignement, j’ai cumulé différents postes tels que les remplacements, la polyvalence et aides au sein des classes ainsi que les séances FLA (Français Langue d’apprentissage).<br/><br/>

Je me réjouis d’accompagner votre enfant dans ses apprentissages, dans son épanouissement personnel et de collaborer à le faire évoluer toute au long de cette nouvelle année scolaire.</p></div>

</div >



                </div>
            </div>

        );
    }

export default Mat2;