import React from "react";
import stephanie from './images/profs/stephanie.jpeg';
import imgTop1 from './images/imgTop1.png';


class Adaptation extends React.Component {
   

    render() {
        return (
            <div>
                <div className="imgTop"><img src={imgTop1} alt={imgTop1} /></div>
                <div className="titrePage"> <h1 >Adaptation</h1></div>
                <div>

                            <div className="nosClassesP3">
                                <div className="descClasseAdaptation"> <h1 className="nomProf1">Stéphanie Bartholomé</h1><img className="imgPhotoProf9" src={stephanie} alt='' /><p>Je suis madame Stéphanie. J'ai plusieurs casquettes au sein de l'école.

Je travaille avec les enfants de la 1ère primaire à la 6ème primaire tantôt en demi groupe, tantôt en groupe de niveaux ou encore en individuel. Les objectifs étant d'aider à la différenciation des apprentissages, de soutenir les enfants en difficulté dans une matière et d'apporter à chacun l'aide dont il a besoin.

Je m'occupe également des heures F.L.A. Ces heures sont destinées à soutenir les enfants dont la langue française pose problème pour la compréhension des apprentissages.

Au plaisir de vous rencontrer.</p></div>

                            </div >

                        
                    

                </div>
            </div>

        );
    }
}
export default Adaptation;