import React from 'react';
import { BrowserRouter as Router, NavLink, Route, Switch, } from "react-router-dom";
/*import AddPrimUnDeux from './AddPrimUnDeux'; */
import P1 from './P1';
import P3 from './P3';
import P5 from './P5';
/* import UpdatePrimundeux from './UpdatePrimundeux'; */



class LesPrimaires extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            show1: false,
            show2: false,


        }

        this.toggleShow = this.toggleShow.bind(this);
        this.toggleShow1 = this.toggleShow1.bind(this);
        this.toggleShow2 = this.toggleShow2.bind(this);
    }


    toggleShow() {
        this.setState({ show: !this.state.show });
        this.setState({ show1: false });
        this.setState({ show2: false });
    }
    toggleShow1() {
        this.setState({ show1: !this.state.show1 });
        this.setState({ show: false });
        this.setState({ show2: false });
    }

    toggleShow2() {
        this.setState({ show2: !this.state.show2 });
        this.setState({ show: false });
        this.setState({ show1: false });
    }





    render() {

        return (


            <div className="primaire">

                <h1 className="titrePrimaire">Les Primaires</h1>
                <div className="">
                    <Router>
                        <ul className="dflex2">
                            <div className="ligneDroite"></div>
                            <div className="ligneDroite1"></div>
                            <li>
                                <NavLink activeClassName="active3" to="/NosClasses/LesPrimaires/P1" className="titreNav1">P1 - P2
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active2" to="/NosClasses/LesPrimaires/P3" className="titreNav1">P3 - P4
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active4" to="/NosClasses/LesPrimaires/P5" className="titreNav1">P5 - P6
                                </NavLink>
                            </li>
                        </ul>
                        <Switch>
                            <Route exact path="/NosClasses/LesPrimaires/P1" component={P1} />
                            <Route exact path="/NosClasses/LesPrimaires/P3" component={P3} />
                            <Route exact path="/NosClasses/LesPrimaires/P5" component={P5} />
                        </Switch>
                    </Router>
                </div>
            </div>


        );
    }
}

export default LesPrimaires;