import ladirection from './images/accueil.png';

function Rgpd() {
    return (


        <div className="repasTot">

            <div className="imgTop"><img src={ladirection} alt={ladirection} /></div>
            <div className="titrePage"><h1>RGPD</h1></div>
            <div className="divTextDirection">
                <p><strong>1</strong> Les informations accessibles sur ce site web sont de nature générale et ne visent pas la situation particulière d’une personne physique ou morale ; elles ne
                    sont pas nécessairement exhaustives, complètes, précises ou à jour ; étant donné qu’on a affaire à des situations changeantes.
                    Ces pages renvoient parfois à des sites extérieurs sur lesquels les services de l’école Anne-Marie n’ont aucun contrôle et à l’égard desquels le propriétaire décline toute responsabilité ;
                    les informations fournies ne constituent pas non plus un avis professionnel dans tous les domaines (pour des avis spécifiques, vous devez toujours consulter un expert dûment qualifié). Les informations,
                    produits et services présentés sur ce site web sont la responsabilité de l’école Anne-Marie et peuvent aussi contenir des inexactitudes techniques et des erreurs typographiques. Le créateur et/ou administrateur
                    de ce site web ne sera en aucun cas tenu responsable de la véracité, ou non véracité des informations qui y figure. Il n’est pas non plus responsable des dommages directs ou indirects, avérés ou allégués pouvant
                    résulter des informations du site web ou ses erreurs. Le propriétaire de ce site est l’école Anne-Marie et par conséquent, il assume l’entière responsabilité quant au choix et à la mise sur internet des différentes
                    illustrations, images, photos et textes qui les accompagnent. <strong>Madame Emilie Montaigne</strong>, la réalisatrice conceptrice de ce site déclare l’avoir fait gratuitement et dans le cadre d’un projet scolaire suite à sa formation chez Interface3. Elle décline, de ce fait, toute responsabilité quant à l’utilisation de ce site pour des buts illégaux ou frauduleux."</p>
                <br/><p><strong>2</strong> Le site web de Ecole Anne-Marie se réserve le droit, à tout moment et pour quelque motif que ce soit, de modifier ou interrompre temporairement ou de manière permanente l’accès à tout ou une partie du site web, et ce sans information préalable. Le site web de l’Ecole Anne-Marie ne pourra être tenu responsable pour tout dommage direct ou indirect, avéré ou allégué, lié à une modification, une suspension ou une interruption du site web.</p>
                <br/><p><strong>3</strong> Le propriétaire veille à prévenir autant que possible les pannes, erreurs et interruptions dues à des problèmes techniques et ce, en fonction du temps qui leur est imparti. Cependant, certaines données ou informations présentes sur le site peuvent ne pas avoir été créées ou structurées dans des fichiers ou formats exempts d’erreurs, de sorte que le propriétaire ne peut pas garantir que le service ne sera pas interrompu ou autrement affecté par de tels problèmes. Le propriétaire décline toute responsabilité quant aux problèmes de ce type pouvant résulter de l’utilisation de ce site ou de tout autre site extérieur auquel il renvoie, tant à l’égard de l’utilisateur qu’à l’égard de tiers.</p>
                <br/><p><strong>4</strong> La présente clause de non-responsabilité n’a pas pour but de limiter la responsabilité du propriétaire de manière contraire aux exigences posées dans la législation nationale et européenne applicable ou d’exclure sa responsabilité dans les cas où elle ne peut l’être en vertu de cette législation.</p>
                <br/><p><strong>5</strong> Le propriétaire et le prestataire créateur et/ou administrateur du site ne seront en aucun cas tenus responsables des actes illégitimes, ni de quelque dommage que ce soit, direct, indirect ou accidentel tel que des pertes d’exploitation, des pertes de bénéfice, la perte d’une chance, des coûts de personnel, même si ceux-ci devaient résulter d’une faute grave ou d’une erreur répétitive rencontrée sur le site web (y compris relative à son fonctionnement technique ou à son indisponibilité), de virus informatiques, de délits informatiques, d’actes de piratage informatique et ce, même si le propriétaire et/ou le prestataire créateur et/ou administrateur du site avai(en)t été averti(s) de ces risques.</p>
                <br/><p><strong>6</strong> Ni le propriétaire, ni le prestataire créateur et/ou administrateur du site ne pourront davantage être tenus pour responsables en cas de dommages ou de perturbations temporaires ou permanents provoqués aux données ou au matériel informatique de l’utilisateur durant l’accès au site web, durant la consultation des pages du site web ou, généralement, durant le transfert de fichiers et de programmes informatiques qui constituent le site web vers son matériel de réception. Le propriétaire et le prestataire créateur et/ou administrateur du site déclinent plus particulièrement toute responsabilité en cas de transfert éventuel de virus informatiques par le biais de ce site web.</p>
                <br/><p><strong>7</strong> Le concepteur de ce site web, <strong>Madame Emilie Montaigne</strong>, n’est pas payée pour ce travail, cependant, la conception du site, sa mise en page et sa mise sur le net restent sa propriété intellectuelle. Il est donc entendu que toute modification ne peut avoir lieu qu’avec le consentement et l’accord préalable de <strong>Madame Emilie Montaigne</strong>. Le non-respect de cette clause peut entrainer des poursuites judiciaires à l’adresse du ou de la contrevenant(e).</p>
            </div ></div>


    );
}

export default Rgpd;