import React from "react";
import caroline from './images/profs/caroline.png';



function P3() {
 
        return (
            <div>


                <div>
              
                            <div  className="nosClassesP3">

                                <div className="descClasseP3"> 
                                <h1 className="nomProf">Madame Caroline</h1>
                                <img className="imgPhotoProf3" src={caroline} alt='' />
                                <p>Bienvenue en troisième et quatrième primaire !<br/><br/>

Une petit description est de mise ;-)<br/><br/>
En classe, chaque élève évolue à son rythme et est acteur de ses apprentissages. Ce qui leur permet de développer une belle autonomie.<br/><br/>

Je suis présente pour les guider et les aider à acquérir les compétences nécessaires. Pour ce faire, les apprentissages sont manipulés afin d’être vécus, compris et intégrés.<br/><br/>

De plus, les iPad sont utilisés au service des apprentissages. Et rien de tel que le travail par ateliers où les jeux et l’entraide sont privilégiés pour apprendre dans la bonne humeur.<br/><br/>

Tout cela dans un climat de respect et de bienveillance.</p></div>


                            </div >

               

                </div>
            </div>

        );
    }

export default P3;