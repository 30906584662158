import React from "react";
import isabelle from './images/profs/Isabelle.jpeg';
import pina from './images/profs/pina.PNG';


function MatAcc() {


        return (
            <div>


                <div>
                 
                            <div className="nosClassesP3">


                                <div className="descClasseM1"> 
                                <h1 className="nomProf">Madame Isabelle</h1>
                                <img className="imgPhotoProf6" src={isabelle} alt='' />
                                <p>Durant la première année en maternelle votre enfant va faire son apprentissage de la vie de l’école. Le perfectionnement du langage et la sociabilisation de l’enfant constituent les deux objectifs principaux de la 1ère mat.<br/><br/>

Il sera normalement encouragé à aller vers ses petits copains et à faire diverses activités de groupe (exemples : atelier peinture, pâte à modeler, coloriage, découpage …)<br/><br/>

Au contact des autres, votre enfant développera peu à peu son sens de l’amitié et du partage. Petit à petit il va apprendre à faire des phrases claires et bien construites. Grâce aux jeux éducatifs et aux histoires lues en classe son vocabulaire va s’enrichir. Il apprendra le nom des couleurs et de divers objets, celui des formes, saura comment s’appellent les différentes parties de son corps …<br/><br/>

En plus du langage parlé votre enfant sera aussi un peu initié au langage écrit en particulier via les étiquettes des prénoms de la classe. Il saura également tenir son crayon pour tracer des lignes, des courbes, colorier.<br/><br/>

Enfin, à l’aide de petits jeux et de comptines mathématiques il apprendra à compter jusqu’au 3 ou 4.</p>
                                </div>
                                <div className="descClasseM1"> 
                                <h1 className="nomProf">Madame Pina</h1>
                                <img className="imgPhotoProf6" src={pina} alt='' />
<p>Mon rôle est de collaborer côte à côte avec Madame Isabelle à l’encadrement des enfants de la classe, pour qu’ils puissent grandir, s’épanouir et se développer dans un cadre serein et de qualité.<br/><br/>
J’essayerai de respecter les besoins de chaque enfant selon son développement.
J’aimerais aussi favoriser l’autonomie, le développement psychomoteur de chacun.<br/><br/>
Apprendre à se faire des amis et à partager sera également un de mes objectifs pour que chacun trouve du bonheur à l’école.
</p>
                                </div>

                            </div >


                </div>
            </div>

        );
    }

export default MatAcc;