import React from "react";
import nosClasses from './images/nosClasses.png';
import points from './images/points.png';
import stephanie from './images/profs/stephanie.jpeg';


function Religion() {

        return (
            <div>
                <img className="point" src={points} alt={points} />
                <div className="imgTop"><img src={nosClasses} alt={nosClasses} /></div>
                <div className="titrePage"> <h1 >Religion</h1></div>
                <div>

                            <div className="nosClassesP3">
                                <div className="descClasseAdaptation"> 
                                <h1 className="nomProf1">Madame Stéphanie</h1>
                                <img className="imgPhotoProf9" src={stephanie} alt='' />
                                <p>Bonjour, je suis madame Stéphanie et je suis en charge du cours de religion. Je suis la méthode " Mosaïques ". Il s'agit d'une méthode qui balaye l'ensemble de compétences à acquérir de la 1ère primaire à la 6ème primaire.<br/><br/>

J'ai à cœur également de proposer des activités réflexives via des jeux de rôles, des situations concrètes, qui développeront la coopération, l'écoute, la tolérance, la bienveillance, l’empathie, ... Toutes compétences nécessaires au développement global de chacun et qui amélioreront le climat de la classe et de l'école.<br/><br/>

Je propose également des activités axées sur le bien-être : travail sur les accords toltèques, relaxation, sophrologie et braingym pour apprendre aux enfants à gérer leurs émotions, à mieux se connaître et à s'ouvrir aux autres. Il me semble essentiel également de proposer un espace et un temps pour apprendre à dialoguer, écouter, débattre, développer son sens critique et accepter chacun.<br/><br/>

Au plaisir de vous rencontrer.</p></div>

                            </div >

              

                </div>
            </div>

        );
    }

export default Religion;