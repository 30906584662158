import contact from './images/contact.png';
import point from './images/point.png';
import phone from './images/phone.png';
import mail from './images/mail.png';
/* import essai1 from './images/body/essai1.png'; */
/* import essai2 from './images/body/essai2.png';
import essai3 from './images/body/essai3.png'; */
//import Login from './Login';

function Contact() {
    return (


        <div className="contactTot">
            {/* <div className="essaiAbsolute"><img src={essai1} alt={essai1} /></div> */}
            {/*  <div className="essaiAbsolute1"><img src={essai2} alt={essai2} /></div>
            <div className="essaiAbsolute2"><img src={essai3} alt={essai3} /></div> */}
            <div className="imgTop"><img src={contact} alt={contact} /></div>
            <div className="titrePage"><h1>Contact</h1></div>
            <div className="aIcon-flex1">
                <div className="aIcon aIconBorder">
                    <div className="rond"><img className="icon" src={point} alt={point} /></div>
                    <p className="titreIcon"><strong>Une visite ?</strong></p>
                    <p>Notre école est ouverte pour vous accueillir. Nous serons heureux de vous voir et de répondre à vos questions...</p>
                    <p><strong>Rue A. De Moor 19 <br />1421 Bois-Seigneur-Isaac</strong></p>
                </div>
                <div className="aIcon aIconBorder">
                    <div className="rond"><img className="icon" src={phone} alt={phone} /></div>
                    <p className="titreIcon"><strong>Un appel ?</strong></p>
                    <p>Préférez-vous nous poser vos questions par téléphone. N'hésitez pas de nous contacter pour parler...</p>
                    <p><strong>067/21 90 09</strong></p>
                    <br />
                </div>
                <div className="aIcon">
                    <div className="rond"><img className="icon" src={mail} alt={mail} /></div>
                    <p className="titreIcon"><strong>Un courriel ?</strong></p>
                    <p>Vous préférez nous envoyer vos questions par messagerie, n'hésitez pas à nous envoyer vos questions...</p>
                    <a className="adressEmail" href="mailto:sjbsecretariat@gmail.com"><strong>sjbsecretariat@gmail.com</strong></a><br/>
                    <a className="adressEmail" href="mailto:direction.ecoleannemarie@yahoo.fr"><strong>direction.ecoleannemarie@yahoo.fr</strong></a>
                </div>
            </div>
            <div className="planEcole"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2530.1266768544865!2d4.318478515738383!3d50.64333847950246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3cc567ee2886d%3A0x47c14602edc60da!2sRue%20Armand%20de%20Moor%2019%2C%201421%20Ophain-Bois-Seigneur-Isaac!5e0!3m2!1sfr!2sbe!4v1639732417820!5m2!1sfr!2sbe" width="600" height="450" loading="lazy" title="planSjb"></iframe></div>

        </div >


    );
}

export default Contact;