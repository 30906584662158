import React from "react";
import fanny from './images/profs/fanny.png';


function P5() {

        return (
            <div>



                <div>

                            <div className="nosClassesP3">


                                <div className="descClasseP5"> 
                                <h1 className="nomProf">Madame Fanny</h1>
                                <img className="imgPhotoProf5" src={fanny} alt='' />
                                <p>Bienvenue en 5 ème et 6 ème primaire ! Je suis Madame Fanny et c’est avec grand plaisir que j’accompagnerai vos enfants durant leurs deux dernières années du primaire. La 5 ème est une année où ils réaliseront un grand nombre de découvertes tandis que la 6 ème est un peu comme une répétition de la 5 ème où ils consolideront et affineront leurs connaissances et compétences. Ces découvertes, ils les effectueront en tâtonnant, en manipulant et grâce à l’aide de leurs camarades de classe. Ils exerceront leurs apprentissages et leurs nouvelles connaissances grâce aux jeux et des petits dossiers où ils pourront progresser à leur propre rythme, tantôt seuls ou en groupe.<br/><br/>

Durant ce cycle, l'un des objectifs principaux est aussi de préparer les enfants pour l'école secondaire en les outillant et en les amenant à progresser sur leur route vers l'autonomie. Ainsi, ils apprendront à s'organiser dans le temps (planification et anticipation du travail hebdomadaire, session d’examens en décembre et en juin) et dans l'espace (ordre, structuration...).<br/><br/>

Il va de soi que la dimension purement scolaire n’est pas la seule qu’ils développeront. En effet, chacun est important à mes yeux et il me tient à cœur de leur offrir un climat d’apprentissages le plus serein et bienveillant possible pour leur permettre d’évoluer dans leur globalité. En ce sens, outre les conseils de classe organisés une fois toutes les 2 semaines (ou de manière ponctuelle si cela est nécessaire), chaque lundi matin, nous prenons le temps de discuter, de déposer ou de raconter ce que nous vivons et ce que nous ressentons. Rien de mieux pour commencer une belle semaine.</p></div>

                            </div >

        

                </div>
            </div>

        );
    }

export default P5;