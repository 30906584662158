import ladirection from './images/accueil.png';
import severine from './images/severine.png';


function LaDirection() {
    return (


        <div>

            <div className="imgTop"><img src={ladirection} alt={ladirection} /></div>
            <div className="barreLiens"> <div className="imgDirection"><img src={severine} alt={severine} /></div><div className="titrePage"><h1>Mot de la Direction</h1></div></div>
            <div className="divTextDirection">

                <h2>Bonjour et Bienvenue à tous!</h2>

                <p>L’école Saint Jean-Baptiste devient une implantation de l’école Anne-Marie, ces deux écoles font partie du Pouvoir Organisateur de l'Institut Saint-Jacques située à Braine-l'Alleud.</p><br/>
                <p>La direction des deux écoles est assurée par Ramay Séverine. Elle est disponible un jour sur deux dans chaque implantation. </p><br/>
                <p>Notre petite école maternelle et primaire se veut familiale, dynamique, et humaine. Elle est située dans un écrin de verdure où petits et grands se sentent bien et participent à l’harmonisation de la vie quotidienne. Le respect du rythme, le travail en cycle, la continuité, l'équité sont nos priorités.</p><br/>
                <p>Il nous tient à cœur de développer l’autonomie dès le plus jeune âge. Nous priorisons la manipulation et le sens donné aux apprentissages.</p><br/>
                <p>Notre équipe est soucieuse d’établir une collaboration avec les parents.</p><br/>
                <p>Nous souhaitons la bienvenue aux nouvelles familles et nous nous réjouissons de vous retrouver tous. </p><br/>
                <p>Au nom de l’équipe éducative, nous vous souhaitons une excellente année scolaire, riche en découvertes et en apprentissages.</p><br/>
                <p>Rejoignez notre équipe dynamique et vivez avec nous des projets inoubliables dans un climat familial et chaleureux. </p><br/><br/>

                Séverine Ramay
                <br />
                Directrice
            </div>
        </div>


    );
}

export default LaDirection;