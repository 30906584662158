import React from 'react';


import slide4 from './images/ecoleSjb.png'

import './App.css';

import Calendar from './Calendrier';
import blackboard from './images/blackboard.png';
import balls from './images/balls.png';

import { NavLink } from 'react-router-dom';

import essai2 from './images/body/essai2.png';
import essai3 from './images/body/essai3.png';
import essai4 from './images/body/essai4.png';
import essai5 from './images/body/essai5.png';
import points from './images/points.png';

import guihaut from './images/guihaut.png';
import groupe from './images/profs/groupe.png'

function App() {

  return (




    <div>
      <img className="point1" src={points} alt={points} />

      <div className="imgTop"><img className='imgAccueil' src={slide4} alt={slide4} /></div>

      <div className="titrePage1"><h1>Bienvenue à l'école <br /> St Jean-Baptiste</h1><p className="titreMatPrim">École maternelle et primaire</p></div>
      <div>
        {/* <div className="essaiAbsolute"><img src={essai1} alt={essai1} /></div> */}
        <div className="essaiAbsolute1"><img src={essai2} alt={essai2} /></div>
        <div className="essaiAbsolute4"><img src={essai3} alt={essai3} /></div>
        <div className="essaiAbsolute3"><img src={essai4} alt={essai4} /></div>
        <div className="essaiAbsolute2"><img src={essai5} alt={essai5} /></div>

      </div>

      <div className="philosophie">
        <img className="guibas" src={guihaut} alt={guihaut} />
        <p>L’école Saint Jean-Baptiste devient une implantation de l’école Anne-Marie, ces deux écoles font partie du Pouvoir Organisateur de l'Institut Saint-Jacques située à Braine-l'Alleud.</p><br/>
        <p>La direction des deux écoles est assurée par Ramay Séverine. Elle est disponible un jour sur deux dans chaque implantation. </p><br/>
        <p>Notre petite école maternelle et primaire se veut familiale, dynamique, et humaine. Elle est située dans un écrin de verdure où petits et grands se sentent bien et participent à l’harmonisation de la vie quotidienne. Le respect du rythme, le travail en cycle, la continuité, l'équité sont nos priorités.</p><br/>
        <p>Il nous tient à cœur de développer l’autonomie dès le plus jeune âge. Nous priorisons la manipulation et le sens donné aux apprentissages.</p><br/>
        <p>Notre équipe est soucieuse d’établir une collaboration avec les parents.</p><br/>
        <p>Nous souhaitons la bienvenue aux nouvelles familles et nous nous réjouissons de vous retrouver tous. </p><br/>
        <p>Au nom de l’équipe éducative, nous vous souhaitons une excellente année scolaire, riche en découvertes et en apprentissages.</p><br/>
        <p>Rejoignez notre équipe dynamique et vivez avec nous des projets inoubliables dans un climat familial et chaleureux. </p><br/>
        <img className="guihaut" src={guihaut} alt={guihaut} />
      </div>

      <div className="aIcon-flex">
        <NavLink className="aIcon" to="/NotreEcole">
          <div className="rond"><img className="icon" src={blackboard} alt={blackboard} /></div>
          <p className="titreIcon">Notre école</p>
        </NavLink>
        <NavLink className="aIcon" to="/NosClasses/Extra">
          <div className="rond"><img className="icon" src={balls} alt={balls} /></div>
          <p className="titreIcon">Extra-scolaire</p>
        </NavLink>

      </div>
      <div className="dflex3">
  
        <div className='accueilFlex'><img className='accueilGroupe' src ={groupe} alt={groupe}/><Calendar /></div>
      </div>



    </div >
  );
}

export default App;