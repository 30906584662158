function Activites() {
    return (


        <div className="activiteTot">


        </div >


    );
}

export default Activites;