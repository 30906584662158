import React from "react";
import gym from './images/gym.png';
import christian from './images/profs/christian.png';


function Gym() {

        return (
            <div>

                <div className="imgTop"><img src={gym} alt={gym} /></div>
                <div className="titrePage"> <h1>Education physique</h1></div>
                <div>

                            <div className="nosClassesP3">
                                <div className="descClasseGym"> 
                                <h1 className="nomProf1">Monsieur Christian</h1>
                                <img className="imgPhotoProf10" src={christian} alt='' />
                                <p><strong><u>En maternelle : La psychomotricité</u></strong><br/><br/>

Tout d’abord c’est l’éducation par le mouvement, l’éducation de l’être tout entier à travers son corps. D’une part, elle permet de développer la prise de conscience par l’enfant de ses potentialités propres. D’autre part, elle favorise l’expression de soi, développe la socialisation. Et enfin, elle permet de développer des apprentissages cognitifs, sociaux, affectifs et psychomoteurs. Tout au long de l’année, j’accompagnerai votre enfant pour qu’il soit l’acteur de sa propre personnalité.<br/><br/>

<strong><u>En primaire : L'éducation physique</u></strong><br/><br/>

L’objectif premier est de contribuer au bien-être de vos enfants et de leur donner le goût de l'activité physique. Par ce biais, je leur transmettrai différentes compétences des trois champs de l'éducation physique. A savoir, l’habileté gestuelle et motrice (maîtrise et coordination des mouvements, équilibre, etc.), la condition physique (endurance, force, souplesse, etc.) et coopération socio-motrice (respect des règles, fairplay). Afin qu’il puisse s’épanouir pleinement.</p></div>

                            </div >


                </div>
            </div>

        );
    }

export default Gym;