import React from 'react';
import pdf from './images/download-pdf.png';
import dossier from './images/imgTop2.png';

import livret from './images/telechargements/livret.pdf';
import ephemerides from './images/telechargements/ephemerides.pdf';
import pms from './images/telechargements/pms.pdf';
import pse from './images/telechargements/pse.pdf';
import romeo from './images/telechargements/romeo.pdf';
import extra from './images/telechargements/extra.pdf';
import motifs from './images/telechargements/motifs.pdf';

function Roi() {



  


        return (
            <div className="">

                <div className="imgTop"><img src={dossier} alt={dossier} /></div>
                <div className="titrePage"> <h1 >Documents à télécharger</h1></div>

                <div className="dflexTotAct">

                            <div className='keyLivret'>

                                <div className="blockLivret">
                                    <h1 className="titreTextActivitePermanente">Livret SJB</h1>
                                    <div className="imgLivret"><a href={livret}download><img className="imgPdf" src={pdf} alt={pdf} /></a></div>

                                </div>
                                <div className="blockLivret">
                                    <h1 className="titreTextActivitePermanente">Ephémérides</h1>
                                    <div className="imgLivret"><a href={ephemerides} download><img className="imgPdf" src={pdf} alt={pdf} /></a></div>

                                </div>
                                <div className="blockLivret">
                                    <h1 className="titreTextActivitePermanente">PMS</h1>
                                    <div className="imgLivret"><a href={pms} download><img className="imgPdf" src={pdf} alt={pdf} /></a></div>

                                </div>
                                <div className="blockLivret">
                                    <h1 className="titreTextActivitePermanente">Service PSE</h1>
                                    <div className="imgLivret"><a href={pse} download><img className="imgPdf" src={pdf} alt={pdf} /></a></div>

                                </div>
                                <div className="blockLivret">
                                    <h1 className="titreTextActivitePermanente">ASBL Romeo</h1>
                                    <div className="imgLivret"><a href={romeo} download><img className="imgPdf" src={pdf} alt={pdf} /></a></div>

                                </div>
                                <div className="blockLivret">
                                    <h1 className="titreTextActivitePermanente">Accueil extrascolaire - Fiche santé</h1>
                                    <div className="imgLivret"><a href={extra} download><img className="imgPdf" src={pdf} alt={pdf} /></a></div>

                                </div>
                                <div className="blockLivret">
                                    <h1 className="titreTextActivitePermanente">Motif d'absence</h1>
                                    <div className="imgLivret"><a href={motifs} download><img className="imgPdf" src={pdf} alt={pdf} /></a></div>

                                </div>

                            </div>

                </div>
            </div>
/*             </div>
*/        )
    }


export default Roi;



