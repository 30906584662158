import React from "react";
import veronique from './images/profs/veronique.png';

function P1() {


        return (
            <div>


                <div>

                            <div className="nosClassesP3">

                                <div className="descClasseP1"> 
                                <h1 className="nomProf">Madame Véronique</h1>
                                <img className="imgPhotoProf1" src={veronique} alt='' />
                                <p>En rentrant dans la classe de madame Véronique, tu trouveras des enfants de 1ère et 2ème primaires qui vivront ensemble toute l'année.<br/><br/>

Lire avec les alphas pour les plus jeunes, écrire, calculer, découvrir, bricoler, chanter, rire, s'écouter, se respecter, s'aider....voilà les apprentissages principaux que nous partagerons ensemble. Tout cela dans la bonne humeur et le respect de chacun. Une institutrice s'occupera des élèves de deuxième année plusieurs heures par semaine afin que je puisse me consacrer aux enfants de première année à 100%. Une remédiation sera également apportée aux enfants en difficulté dans la mesure du possible.<br/><br/>

Chaque matin, nous vivrons un petit moment de partage avant de commencer notre journée de travail. C'est à ce moment-là que tu pourras apporter tes livres préférés, ton doudou qui passera la journée avec nous ou tes surprises intéressantes qui enchanteront les autres. Bienvenue dans ma classe.<br/><br/>

Je t'attends !</p></div>

                            </div>



                </div>
            </div>

        );
    }

export default P1;