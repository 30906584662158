import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import 'moment/locale/fr';


class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            month: moment(),
            selected: moment().startOf('day')
        };

        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
    }

    previous() {
        const {
            month,
        } = this.state;

        this.setState({
            month: month.subtract(1, 'month'),
        });
    }

    next() {
        const {
            month,
        } = this.state;

        this.setState({
            month: month.add(1, 'month'),
        });
    }

    select(day) {
        this.setState({
            selected: day.date,
            month: day.date.clone(),

        });
    }

    renderWeeks() {
        let weeks = [];
        let done = false;
        let date = this.state.month.clone().startOf("month").add("w" - 1).day("Sunday");
        let count = 0;
        let monthIndex = date.month();

        const {
            selected,
            month,
        } = this.state;

        while (!done) {
            weeks.push(
                <Week key={date}
                    date={date.clone()}
                    month={month}
                    select={(day) => this.select(day)}
                    selected={selected} />
            );

            date.add(1, "w");

            done = count++ > 2 && monthIndex !== date.month();
            monthIndex = date.month();

        }

        return weeks;
    };

    renderMonthLabel() {
        const {
            month,
        } = this.state;

        return <span className="month-label">{month.format("MMMM YYYY")}</span>;
    }

    render() {
        return (
            <section className="calendar">
                <header className="header">
                    <div className="month-display row1">
                        <FontAwesomeIcon className="leftRight" icon={faArrowAltCircleLeft} onClick={this.previous} />
                        {this.renderMonthLabel()}
                        <FontAwesomeIcon className="leftRight" icon={faArrowAltCircleRight} onClick={this.next} />
                    </div>
                    <DayNames />
                </header>
                {this.renderWeeks()}
            </section>
        );
    }
}

class DayNames extends React.Component {
    render() {
        return (
            <div className="row1 day-names">
                <span className="day"><span className="jourSem">Dim</span></span>
                <span className="day"><span className="jourSem">Lun</span></span>
                <span className="day"><span className="jourSem">Mar</span></span>
                <span className="day"><span className="jourSem">Mer</span></span>
                <span className="day"><span className="jourSem">Jeu</span></span>
                <span className="day"><span className="jourSem">Ven</span></span>
                <span className="day"><span className="jourSem">Sam</span></span>
            </div>
        );
    }
}

class Week extends React.Component {
    render() {
        let days = [];
        let {
            date,
        } = this.props;

        const {
            month,
            selected,
            select,
        } = this.props;

        for (var i = 0; i < 7; i++) {
            let day = {
                name: date.format("dd").substring(0, 1),
                number: date.date(),
                isCurrentMonth: date.month() === month.month(),
                isToday: date.isSame(new Date(), "day"),
                date: date
            };
            days.push(
                <Day day={day}
                    selected={selected}
                    select={select} />
            );

            date = date.clone();
            date.add(1, "day");
        }

        return (

            <div className="row1 week">
                {days.map(function (day, i) {
                    return <div className="row1" key={i}>{day}</div>

                })}

            </div>
        );
    }

}

class Day extends React.Component {
    render() {
        const {
            day,
            day: {
                date,
                isCurrentMonth,
                isToday,
                number
            },
            select,
            selected
        } = this.props;

        return (
            <span
                key={date.toString()}
                className={"day" + (isToday ? " today" : "") + (isCurrentMonth ? "" : " different-month") + (date.isSame(selected) ? " selected" : "")}
                onClick={() => select(day)}><div className="numJour">{number}</div></span>
        );
    }
}

export default Calendar;