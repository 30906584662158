import React from "react";
import nosClasses from './images/permanente.png';
import hel from './images/profs/hel.jpg';
import nathalie from './images/profs/nathalie.jpeg'


function LaGarderie() {

        return (
            <div>
                <div className="imgTop"><img src={nosClasses} alt={nosClasses} /></div>
                <div className="titrePage"> <h1 >Extra-scolaire</h1></div>
                <div>

                            <div className="nosClassesP3">

                                <div className="descClasseGarderie">
                                   <div className="profGard"><img className="imgPhotoProfGarderie" src={hel} alt='' />
                                    <img className="imgPhotoProfGarderie" src={nathalie} alt='' /></div> 
                                    <h1 className="nomProfGarderie">Madame Hélène et<br/>Madame Valérie</h1>
                                    <p>L’accueil extrascolaire est assuré par Madame Hélène et Madame Valérie dans un cadre familial et bienveillant.<br/><br/>

L'école est ouverte de 7h à 18h tous les lundis, mardis, jeudis et vendredis et de 7h à 13h le mercredi.<br/><br/>

<u>L’accueil du matin :</u><br/>
accueille les enfants de 7h à 8h30 et fonctionne tous les jours où l'école est ouverte.<br/><br/>

<u>L’accueil du soir :</u><br/>
accueille les enfants de 15h30 à 18h.<br/><br/>

<u>L’accueil du mercredi après-midi :</u><br/>
les enfants apportent leur pique-nique + une boisson et mangent à l'école. La garderie à Saint-Jean-Baptiste se fait jusque maximum 13h00. Les enfants sont ensuite conduits en car à l'école du Grand Frêne par les services communaux où ils seront pris en charge par les animateurs de la commune.<br/><br/>

L’inscription à l’accueil du mercredi après-midi au Grand Frêne se fait via le site internet de la commune de Braine-l’Alleud. L’inscription préalable est obligatoire pour que votre enfant soit pris en charge.
Vous trouverez plus loin un tableau récapitulatif des tarifs de l’accueil extrascolaire.<br/><br/><br/><br/>


Les cours débutent à 8h45 tous les jours pour toutes les classes.<br/><br/>

La cour est surveillée à partir de 8h30. Les enfants qui arrivent plus tôt doivent se rendre à la garderie ; ils ne peuvent rester sans surveillance dans la cour.<br/><br/>

Avant 8h30, les enfants sont conduits à la garderie.<br/><br/>

À partir de 8h30, les parents embrassent leur enfant entre les deux grilles dans l’espace baptisé « Place des Bisous » et ne rentrent pas dans la cour, comme à la fin des cours.<br/><br/>

Il y a en effet beaucoup de monde le matin dans la cour et l’espace dévolu aux enfants est de ce fait restreint.<br/><br/>

Tarifs des frais extrascolaires :
- Accueil matin/soir € 0,60 la demi-heure entamée pour l’aîné et 0,40€ les suivants<br/><br/>

<strong>L’ÉTUDE ENCADRÉE</strong><br/>
Cette étude est organisée par une ASBL extérieure (Educ&Sport - http://www.educ-etsport.be/etude/) pour les élèves de la 2ème à la 6ème primaire à l’école de 15h30 à 16h30 et assurée par une ou plusieurs enseignante(s).<br/><br/>

<strong>Attention, il n’y a plus d’étude les lundis.</strong> </p>
                                </div>

                            </div >

    

                </div>
            </div>

        );
    }

export default LaGarderie;