function Team() {
    return (


        <div className="repasTot">
            <div className="imgTop"></div>
            {/* <div className="barreLiens "> <h1 className="titrePage">L'équipe éducative</h1></div> */}
            <div className="divVide"></div>
        </div >


    );
}

export default Team;