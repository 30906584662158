
import React from 'react';
import Mat2 from './Mat2';
import MatAcc from './MatAcc';
import { BrowserRouter as Router, NavLink, Route, Switch } from "react-router-dom";


class LesMaternelles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            show1: false,
            show2: false,


        }

        this.toggleShow = this.toggleShow.bind(this);
        this.toggleShow1 = this.toggleShow1.bind(this);

    }


    toggleShow() {
        this.setState({ show: !this.state.show });
        this.setState({ show1: false });
        this.setState({ show2: false });
    }
    toggleShow1() {
        this.setState({ show1: !this.state.show1 });
        this.setState({ show: false });
        this.setState({ show2: false });
    }






    render() {

        return (


            <div className="primaire">

                <h1 className="titrePrimaire">Les Maternelles</h1>
                {/* <AddPrimUnDeux /> */}
                {/* <UpdatePrimundeux /> */}

                {/* <div className="barreLiens"> <h1>BIENVENUE SUR LA PAGE LES Primaires</h1></div> */}
                <div className="">
                    <Router>
                        <ul className="dflex2">
                            <div className="ligneDroite2"></div>

                            <li className="liMat1">
                                <NavLink activeClassName='active5' to='/NosClasses/LesMaternelles/MatAcc' className="titreNav1" onClick={this.toggleShow}>Mat Acc - 1
                                </NavLink>
                            </li>
                            <li className="liMat2">
                                <NavLink activeClassName='active6' to='/NosClasses/LesMaternelles/Mat2' className="titreNav1" onClick={this.toggleShow1}>Mat 2 - 3
                                </NavLink>
                            </li>
                        </ul>
                        <Switch>
                            <Route exact path="/NosClasses/LesMaternelles/MatAcc" component={MatAcc} />
                            <Route exact path="/NosClasses/LesMaternelles/Mat2" component={Mat2} />
                        </Switch>
                    </Router>
                </div>
                {/* 
                <div>
                    {
                        this.state.show &&
                        (<MatAcc />)
                    }
                    {
                        this.state.show1 &&
                        (<Mat2 />)
                    }

                </div> */}
            </div>


        );
    }
}

export default LesMaternelles;