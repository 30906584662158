import React from "react";
import ndlr from './images/ndlr.jpg';
import ndlrprof from './images/profs/ndlr.jpg';

function Neerlandais() {


        return (
            <div>
                <div className="imgTop"><img src={ndlr} alt={ndlr} /></div>
                <div className="titrePage"> <h1 >Le cours de Néerlandais</h1></div>
                <div>

                            <div  className="nosClassesP3">
                                <div className="descClasseNdlr"> 
                                <h1 className="nomProf">Madame Valérie</h1>
                                <img className="imgPhotoProf8" src={ndlrprof} alt='' />
                                <p>Le cours de néerlandais en 5 ème et 6 ème primaire est donné à raison de 2 périodes par semaine.<br/><br/>
L’objectif principal est la communication orale. La méthode utilisée est active, attrayante et
ludique, les tâches de communication sont contextualisées et les élèves apprennent tout en
s’amusant.<br/><br/>
Nous travaillons avec le manuel « Onderweg 5» qui contient des dialogues, des bandes
dessinées, des vidéos, des chansons, des jeux, des exercices courts et ciblés. Ceux-ci
permettent d’élargir les apprentissages, tout en proposant des activités de dépassement et
de différenciation. Un CD audio et des vidéos sont accessibles pour améliorer la
compréhension de la langue et la prononciation.<br/><br/>
Le cours répond aux exigences du programme de l’enseignement fondamental en travaillant
les compétences orales, à l’audition, à la lecture et à l’écrit.</p></div>

                            </div>



                </div>
            </div>

        );
    }

export default Neerlandais;